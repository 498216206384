{
    "name": "wcs-core",
    "version": "7.0.0",
    "description": "Web Components SNCF",
    "main": "dist/index.cjs.js",
    "module": "dist/index.js",
    "es2015": "dist/esm/index.mjs",
    "es2017": "dist/esm/index.mjs",
    "types": "dist/types/index.d.ts",
    "collection": "dist/collection/collection-manifest.json",
    "collection:main": "dist/collection/index.js",
    "unpkg": "dist/wcs/wcs.js",
    "files": [
        "dist/",
        "loader/",
        "design-tokens/dist/"
    ],
    "scripts": {
        "build:stencil": "stencil build",
        "generate-custom-element-manifest": "cd src && custom-elements-manifest analyze --stencil --outdir ../",
        "generate-docs-json": "stencil build --docs-json ./stories/documentation/generated/docs.json",
        "build": "npm run generate-design-tokens && stencil build && npm run generate-custom-element-manifest && npm run build-storybook",
        "generate-design-tokens": "node design-tokens/index.mjs ",
        "watch:stencil": "stencil build --dev --watch",
        "start": "npm run generate-design-tokens && concurrently --names \"Stencil,Storybook\" -c \"bgBlue.bold,bgMagenta.bold\" \"stencil build --dev --watch\" \"npm run storybook\"",
        "test": "stencil test --spec --e2e",
        "test:ci": "stencil test --spec --e2e --ci",
        "test:watch": "stencil test --spec --e2e --watchAll",
        "test:e2e": "stencil test --e2e",
        "test:e2e:file": "stencil test --e2e --watch --",
        "update-version": "node scripts/update-version.js",
        "update-develop": "node scripts/update-develop-from-master.js",
        "storybook": "storybook dev -p 6006",
        "build-storybook": "storybook build",
        "build-angular-libs": "cd angular && npm run build:wcs-angular && npm run build:wcs-formly"
    },
    "devDependencies": {
        "@babel/preset-env": "^7.20.2",
        "@babel/preset-typescript": "^7.18.6",
        "@custom-elements-manifest/analyzer": "^0.8.3",
        "@ionic/eslint-config": "^0.3.0",
        "@material/ripple": "13.0.0",
        "@stencil/angular-output-target": "0.8.4",
        "@stencil/core": "4.17.2",
        "@stencil/react-output-target": "0.5.3",
        "@stencil/sass": "3.0.12",
        "@storybook/addon-a11y": "^7.0.24",
        "@storybook/addon-actions": "^7.0.24",
        "@storybook/addon-docs": "^7.0.24",
        "@storybook/addon-essentials": "^7.0.24",
        "@storybook/addon-links": "^7.0.24",
        "@storybook/addons": "^7.0.24",
        "@storybook/blocks": "^7.0.24",
        "@storybook/components": "^7.0.24",
        "@storybook/icons": "^1.2.12",
        "@storybook/theming": "^7.0.24",
        "@storybook/web-components": "^7.0.24",
        "@storybook/web-components-webpack5": "^7.0.24",
        "@types/dom-inputevent": "^1.0.5",
        "@types/glob": "^7.1.3",
        "@types/jest": "^29.5.12",
        "@types/lodash-es": "^4.17.9",
        "@typescript-eslint/eslint-plugin": "^5.18.0",
        "@typescript-eslint/parser": "^5.18.0",
        "concurrently": "^5.2.0",
        "eslint": "^8.12.0",
        "glob": "^7.1.6",
        "jest": "^29.7.0",
        "jest-cli": "^29.7.0",
        "lit-html": "^2.6.1",
        "puppeteer": "21.11.0",
        "react": "^18.2.0",
        "react-dom": "^18.2.0",
        "storybook": "^7.0.24",
        "tslib": "2.5.0",
        "typescript": "4.9.5",
        "webpack-watch-files-plugin": "^1.2.0",
        "style-dictionary": "^4.0.1"
    },
    "dependencies": {
        "@popperjs/core": "^2.9.3",
        "chalk": "^4.1.0",
        "lodash-es": "^4.17.21",
        "tippy.js": "^6.3.7",
        "uuid": "^8.3.2",
        "xstate": "^4.11.0"
    },
    "repository": {
        "type": "git",
        "url": "git+https://gitlab.com/SNCF/wcs"
    },
    "author": "SNCF",
    "private": false,
    "license": "MIT",
    "customElements": "custom-elements.json"
}
